<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-alert dense outlined colored-border border="left" type="info">
          Utilize as tags abaixo de cada mensagem para que ela seja
          autocompletada pelo sistema.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Mensagem de cobrança Mensal</span><small> (Automático)
        </small>
        <v-textarea id="message_billing" v-model="form.message_billing" outlined hide-details auto-grow class="mb-2" />
        <small class="text-muted">
          **Clique nos botões abaixo para usar as tags e autocompletar sua mensagem com as
          informações necessárias!"
        </small>
        <v-row>
          <v-col>
            <div v-for="tag in [...commonTags, ...monthBillingTags]" class="d-inline-block">
              <v-chip class="ma-1" @click="tagInsert('message_billing', tag.value)" small>
                {{ tag.label }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Mensagem de cobrança</span> <small>(Manual)</small>
        <v-textarea id="transaction_billing_message" v-model="form.transaction_billing_message" outlined hide-details
          class="mb-2" auto-grow />
        <small class="text-muted">
          **Clique nos botões abaixo para usar as tags e autocompletar sua mensagem com as
          informações necessárias!"
        </small>
        <v-row>

          <v-col>
            <div v-for="tag in [...commonTags, ...billingMessageOptions]" class="d-inline-block">
              <v-chip class="ma-1" @click="tagInsert('transaction_billing_message', tag.value)" small>
                {{ tag.label }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Lembrete de Sessão</span>
        <v-textarea id="session_reminder_message" v-model="form.session_reminder_message" outlined hide-details
          class="mb-2" auto-grow />
        <small class="text-muted">
          **Clique nos botões abaixo para usar as tags e autocompletar sua mensagem com as
          informações necessárias!"
        </small>
        <v-row>
          <v-col>
            <div v-for="tag in [...commonTags, ...sessionTags]" class="d-inline-block">
              <v-chip class="ma-1" @click="tagInsert('session_reminder_message', tag.value)" small>
                {{ tag.label }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-switch v-model="form.session_reminder_enable_cancellation" label="Habilitar  cancelamento e reagendamento"
          :true-value="1" :false-value="0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <span class="text-h6">Lembrete de Sessão Suspensa</span>
        <v-textarea id="suspended_message" v-model="form.suspended_message" outlined hide-details class="mb-2"
          auto-grow />
        <small class="text-muted">
          **Clique nos botões abaixo para usar as tags e autocompletar sua mensagem com as
          informações necessárias!"
        </small>
        <v-row>
          <v-col>
            <div v-for="tag in [...commonTags, ...sessionTags]" class="d-inline-block">
              <v-chip class="ma-1" @click="tagInsert('suspended_message', tag.value)" small>
                {{ tag.label }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end" cols="12" md="8" offset-md="2">
        <app-confirm-btn @click="save()">Salvar</app-confirm-btn>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      form: {
        message_billing: null,
        transaction_billing_message: null,
        session_reminder_message: null,
      },

      commonTags: [
        {
          label: `Nome do ${customerAlias().toLowerCase()}`,
          value: `{nome_${customerAlias().toLowerCase()}}`,
        },
        {
          label: `Primeiro nome do ${customerAlias().toLowerCase()}`,
          value: `{primeiro_nome_${customerAlias().toLowerCase()}}`,
        },
        {
          label: 'Meu contato',
          value: '{meu_contato}',
        },
        {
          label: 'Link do meu contato',
          value: '{link_meu_contato}',
        },

        {
          label: 'Primeiro nome do profissional',
          value: '{primeiro_nome_profissional}',
        },

        {
          label: 'Nome do profissional',
          value: '{nome_profissional}',
        }

      ],

      monthBillingTags: [
        {
          value: '{nome_responsavel_financeiro}',
          label: 'Responsável financeiro',
        },
        {
          label: 'Detalhes',
          value: '{detalhes}'
        },
        {
          label: 'Valor',
          value: '{valor}',
        },
        {
          label: 'Valor por extenso',
          value: '{valor_extenso}',
        },
        {
          label: 'Valor da sessão',
          value: '{valor_sessao}',
        },
        {
          label: 'Valor da sessão por extenso',
          value: '{valor_sessao_extenso}',
        },



      ],

      billingMessageOptions: [
        {
          value: '{nome_responsavel_financeiro}',
          label: 'Responsável financeiro',
        },
        {
          label: "Valor",
          value: "{valor}",
        },
        {
          label: "Valor por extenso",
          value: "{valor_extenso}"
        },
        {
          label: "Descrição",
          value: "{descricao}"
        },


      ],

      sessionTags: [
        {
          label: 'Data',
          value: '{data}'
        },
        {
          label: 'Hora',
          value: '{hora}'
        },
      ],
    };
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },
    customer_alias() {
      return customerAlias().toLowerCase();
    },



    sections() {
      return [
        {
          title: 'Lembrete de cobrança Mensal',
          id: 'message_billing',
          tags: [...this.commonTags, ...this.monthBillingTags]
        },
        {
          title: 'Mensagem de cobrança',
          id: 'transaction_billing_message',
          tags: [...this.commonTags, ...this.billingMessageOptions]
        },
        {
          title: 'Lembrete de Sessão',
          id: 'session_reminder_message',
          tags: [...this.commonTags, ...this.sessionTags]
        },
        {
          title: 'Lembrete de Sessão Suspensa',
          id: 'suspended_message',
          tags: [...this.commonTags, ...this.sessionTags]
        }
      ]

    },
  },

  mounted() {
    this.$loading.start();
    this.$auth
      .getLoginData()
      .then((response) => {
        this.form = JSON.parse(JSON.stringify(this.company));
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
    //
  },
  methods: {
    save() {
      this.$loading.start();
      this.$http
        .update("company/company", this.company.id, this.form)
        .then((response) => {
          this.$router.push("/configuracoes");
          this.$auth.getLoginData();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    tagInsert(id, value) {
      // localize a  posição do cursor no textarea de id 'message_billing' e insira a tag selecionada
      const textarea = document.getElementById(id);
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      textarea.value = before + value + after;
      textarea.focus();

      // reposicione o cursor
      textarea.selectionStart = start + value.length;
      textarea.selectionEnd = start + value.length;

      // atualize o v-model
      this.form[id] = textarea.value;


    }
  },
};
</script>

<style>
@media (max-width: 600px) {
  #container {

    /* .v-card__title {
      padding: 0 !important;
    }

    .v-card__text {
      padding: 0 !important;
    }

    .v-card__title {
      padding: 0 !important;
    } */
  }



}
</style>
